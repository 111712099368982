import { useEffect, useState } from "react";
import { getStartOfDay } from "../utils/common";

var MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
var DAYS_OF_WEEK = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

function measureDuration(diff) {
	let msPerMinute = 60 * 1000;
	let msPerHour = msPerMinute * 60;
	let msPerDay = msPerHour * 24;
	let msPerMonth = msPerDay * 30;
	let msPerYear = msPerDay * 365;

	let time = 0;
	let measure = "";
	let remaining_diff = 0;
	if (diff < msPerMinute) {
		measure = "second";
		time = Math.round(diff/1000);
	}

	else if (diff < msPerHour) {
		measure = "minute";
		const precise_time = diff/msPerMinute;
		time = Math.round(precise_time); 
		remaining_diff = (precise_time - Math.floor(precise_time)) * msPerMinute
	}

	else if (diff < msPerDay ) {
		measure = "hour";
		const precise_time = diff/msPerHour;
		time = Math.round(precise_time); 
		remaining_diff = (precise_time - Math.floor(precise_time)) * msPerHour
	}

	else if (diff < msPerMonth) {
		measure = "day";
		const precise_time = diff/msPerDay;
		time = Math.round(precise_time); 
		remaining_diff = (precise_time - Math.floor(precise_time)) * msPerDay
	}

	else if (diff < msPerYear) {
		measure = "month";
		const precise_time = diff/msPerMonth;
		time = Math.round(precise_time); 
		remaining_diff = (precise_time - Math.floor(precise_time)) * msPerMonth
	}

	else {
		measure = "year";
		const precise_time = diff/msPerYear;
		time = Math.round(precise_time); 
		remaining_diff = (precise_time - Math.floor(precise_time)) * msPerYear
	}
	if (time !== 1) measure += 's';
	return {time, measure, remaining_diff};
}

function relativeTimeString(millis, relative, precision){
	let diff = Math.abs(relative - millis);
	const {time, measure, remaining_diff} = measureDuration(diff);
	let time_string = `${time} ${measure}`;
	if (precision && remaining_diff > precision) {
		const {time: precise_time, measure: precise_measure} = measureDuration(remaining_diff);
		time_string = `${time_string} ${precise_time} ${precise_measure}`;
	}
	if(relative < millis){
		return `in ${time_string}`;
	}
	return `${time_string} ago`;
}


function millisToDateString(millis, exclude_time){
	let ret = "";
	if(typeof(millis) != "number"){
		return ret;
	}
	let date = new Date(millis);
	
	let start_of_day = new Date(date.getFullYear(), date.getMonth(), date.getDate());
	let start_of_today = getStartOfDay(new Date())

	let start_of_day_millis = start_of_day.getTime();
	let start_of_today_millis = start_of_today.getTime();
	if(start_of_today_millis == start_of_day_millis){
		ret += "Today "
	} else{
		ret += DAYS_OF_WEEK[date.getDay()].slice(0,3) + ", ";
		ret += date.getDate() + " ";
		ret += MONTHS[date.getMonth()].slice(0,3) + " ";
		if(date.getFullYear() != start_of_today.getFullYear()){
			ret += start_of_day.getFullYear() + " ";
		}
	}
	// should include time
	if(!(exclude_time || start_of_day_millis == millis)){
		ret += date.toLocaleTimeString([], { hour12: true}).replace(":00 ", " ");
	}
	
	return ret;
}

function DateView({millis, relative, className, exclude_time, onClick, precision}){   
	return <span className={className} onClick={onClick}>
		{
			relative
			? relativeTimeString(millis, relative === true ? new Date().getTime(): relative, precision)
			: millisToDateString(millis, exclude_time)
		}
	</span>;
}

function SecondsView({seconds, accurate}){
	var levels = [
		[Math.floor(seconds / 31536000), 'years', 365],
		[Math.floor((seconds % 31536000) / 86400), 'days', 24],
		[Math.floor(((seconds % 31536000) % 86400) / 3600), 'hours', 60],
		[Math.floor((((seconds % 31536000) % 86400) % 3600) / 60), 'minutes', 60],
		[(((seconds % 31536000) % 86400) % 3600) % 60, 'seconds']
	];
	var returntext = '';

	for (var i = 0, max = levels.length; i < max; i++) {
		if(!accurate && i>0 && (levels[i-1][0]*levels[i-1][2]*0.2 > levels[i][0])){
			break;// less
		}                                                                                // than
		if ( levels[i][0] === 0 ) continue;
		returntext += ' ' + levels[i][0] + ' ' + (levels[i][0] === 1 ? levels[i][1].substr(0, levels[i][1].length-1): levels[i][1]);
	};
	return returntext.trim();
}

function timeRangeToString([start, end]){
	let time_a = millisToDateString(start).split(" ");
	let time_b = millisToDateString(end).split(" ");
	let i = 0;
	for(i=0;i<3;i++){
		if(time_a[i] != time_b[i]){
			break;
		}
	}
	return [
		time_a.slice(0, i).join(" "),
		time_a.slice(i).join(" ") + " - " + time_b.slice(i).join(" ")
	];
}

function TimeRangeView({time_range, className, ...attrs}){
	let [date, time] = timeRangeToString(time_range);
	return (
		<div className={className} {...attrs}>
			{date ? date + " | " : ""}{time}
		</div>
	);
}

function duration2string(seconds){
	var remaining = seconds;
	var d = Math.floor(remaining / (60*60*24));
	remaining = seconds - d * (60*60*24);
	var h = Math.floor(remaining / (60*60));
	remaining = remaining - h * (60*60);
	var m = Math.floor(remaining / 60);
	remaining = remaining - m * 60;
	var s = remaining;
	var ret = "";
	if(d){
		ret += `${d}d`;
	}
	if(h){
		ret += `${h}h`;
	}
	if(m){
		ret += `${m}m`;
	}
	if(s){
		ret += `${s}s`;
	}
	return ret;
}



function string2duration(duration_str){
	let ret = 0;
	try{
		var splits = duration_str.match(/([a-zA-Z]+)|(\d+)/g);
		for (let i = 0; i < splits.length; i+=2){
			let v = parseInt(splits[i]);
			let c = splits[i + 1];
			if(c[0] == 'd'){
				ret += v * 24 * 60 * 60;
			}
			if(c[0] == 'h'){
				ret += v * 60 * 60;
			}
			if(c[0] == 'm'){
				ret += v * 60;
			}
			if(c[0] == 's'){
				ret += v;
			}
		}
	} catch(e){
		console.log(e);
	}
	return ret;
}


/**
 * 
 * @param {*} date 
 * @param {*} include_year 
 * @returns dd MM -or- dd MMM yyyy
 */
function toGraphsFormat(date, include_year) {
	const day = date.getDate();
	const monthIndex = date.getMonth();
	const monthName = MONTHS[monthIndex].slice(0, 3);
	const year = date.getFullYear();
	return `${day} ${monthName} ${include_year ? year : ""}`;
}

function CountDownView({millis, timestamp, className, onEnd}){
	const [countdownString, setCountDownString] = useState("");
	
	useEffect(
		() => {
			var countdownto = new Date().getTime();
			if(millis){
				countdownto = countdownto + parseInt(millis)
			}
			if(timestamp){
				countdownto = parseInt(timestamp);
			}
			var timer_id = setInterval(
				function() {
					// Get today's date and time
					let now = new Date().getTime();
			
					// Find the distance between now and the count down date
					let distance = countdownto - now;
			
					if (distance < 0) {
						timer_id && clearInterval(timer_id);
						distance = 0;
						onEnd && onEnd();
					}
					// Time calculations for days, hours, minutes and seconds
					let days = Math.floor(distance / (1000 * 60 * 60 * 24));
					let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
					let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
					let seconds = Math.floor((distance % (1000 * 60)) / 1000);
					let start = 0;
					let str = ""; 
					if(days > 0 || start){
						str = days + "d ";
						start = 1;
					}
					if(hours > 0 || start){
						str = str + hours + "h ";
						start = 1;
					}			  
					if(minutes > 0 || start){
						str = str + minutes + "m ";
						start = 1;
					}
					if(seconds > 0 || !start){
						str = str + seconds + "s ";
						start = 1;
					}			  
					setCountDownString(str);
				}, 1000
			);
			// cleanup
			return () => clearInterval(timer_id);
		}, []
	);
	return <span className={className}>{countdownString}</span>
}

export {DateView, SecondsView, CountDownView, millisToDateString, timeRangeToString, TimeRangeView, 
	measureDuration, toGraphsFormat, duration2string, string2duration
};