import { MILLIS_IN_A_DAY } from "base/constants";
import { TagsInput } from "base/ui/misc";
import { Popup } from "base/ui/popups";
import { idToTitle, last } from "base/utils";
import { sanitizeToId } from "base/utils/common";
import React, { useEffect, useState } from "react";


function SearchTagSuggestionDropDownView({tag, tag_type, selection}){
	return (
		<div className='tw-space-1'>
			<div className='tw-grow'>Tag: <b>{idToTitle(tag)}</b></div>
			{
				tag_type === "date"
				?	<div className="">
						<div className='tw-flex tw-gap-1 tw-flex-align-center'>
							<b>from</b>
							<input type='datetime-local'
								onChange={(evt) => {
									// to epoch seconds
									console.log(evt.target.value)
									selection.from = new Date(evt.target.value).getTime()
									selection.from_str = evt.target.value;
									selection.title = `${idToTitle(tag)} [${selection.from_str} - ${selection.to_str || "Any"}]`
								}}
								onClick={(evt) => evt.stopPropagation()}
							/>
						</div>
						<div className='tw-flex tw-gap-1 tw-flex-align-center'>
							<b>to</b>
							<input type='datetime-local'
								onChange={(evt) => {
									// to epoch seconds
									selection.to = new Date(evt.target.value).getTime()
									selection.to_str = evt.target.value;
									selection.title = `${idToTitle(tag)} [${selection.from_str || "Any"} - ${selection.to_str}]`
								}}
								onClick={(evt) => evt.stopPropagation()}
							/>
						</div>
					</div>
				:   null
			}
		</div>
	)
}

function SearchTagsWithEsValues({tag_types, onTagsChange, onTextSearch}){
	return (
		<TagsInput
			onSelectedChange={
				(selected) => {
					// convert to ES query
					let tags_with_es_values = Object.fromEntries(
						selected.map((tag) => {
							let values = [];
							if(tag.from){values.push("gte:" + tag.from)}
							if(tag.to){values.push("lte:" + tag.to)}
							return [tag.tag, values.join(",")]
						})
					);
					onTagsChange?.(tags_with_es_values)
				}
			}
			onTextSearch={onTextSearch}
			suggestions={
				async (text) => {
					if(text.length < 3) return;
					let suggestions = [{"view": <>{"Search for \""}<b>{text}</b>{"\""}</>, "selection": null}]; // for text search

					for(let [tag, tag_type] of Object.entries(tag_types)){
						if(tag.startsWith(text)){
							let suggestion_obj = {"selection": {"title": tag, "tag": tag, "tag_type": tag_type}}; // from: 0, to: 0
							suggestion_obj.view = (
								<SearchTagSuggestionDropDownView 
									tag={tag} tag_type={tag_type} 
									selection={suggestion_obj.selection} 
								/>
							);
							suggestions.push(suggestion_obj)
						}
					}
					return suggestions;
				}
			}		
		/>
	)
}

function AddTagsWithValues({selected:_selected, tag_types, onTagsChange}){
	let [selected, setSelected] = useState(_selected);

	useEffect(
		() => {
			let date_check = new Date().getTime() - 5 * 365 * MILLIS_IN_A_DAY;
			setSelected(Object.entries(_selected || {}).map(([tag, value]) => {
				let title = idToTitle(tag);
				if(value > date_check){
					title += ": " + new Date(value).toLocaleDateString();
				}
				return {"title": title, "tag": tag, "value": value}
			}));
		}, [_selected]
	);

	return (
		<TagsInput
			placeholder={"Add Tags"}
			selected={selected}
			onSelectedChange={
				(selected) => {
					let tags_with_value = Object.fromEntries(
						selected.map((tag) => {return [tag.tag, tag.value || ""]})
					);
					onTagsChange?.(tags_with_value)
				}
			}
			suggestions={
				async (text) => {
					if(text.length < 3) return;
					let suggestions = [];
					for(let [tag, tag_type] of Object.entries(tag_types)){
						if(tag.startsWith(text)){
							let ret = {"selection": {"title": tag, "tag": tag}};
							ret.view = (
								<div className='tw-flex tw-gap-1 tw-flex-align-center'>
									<div className='tw-grow'>{idToTitle(tag)}</div>
									{
										tag_type === "date"
										?	<input type='datetime-local'
												onChange={(evt) => {
													// to epoch seconds
													ret.selection.value = new Date(evt.target.value).getTime()
													ret.selection.value_str = evt.target.value;
													ret.selection.title = `${idToTitle(tag)} [${ret.selection.value_str}]`
												}}
												onClick={(evt) => evt.stopPropagation()}
											/>
										:	null
									}
								</div>
							);
							suggestions.push(ret)
						}
					}
					return suggestions;
				}
			}
		/>
	)

}
/* THis will render a component to update tag types */
function TagsWithTypesEditor({tag_types, onUpdated}){
	return (
		<TagsInput
			selected={
				Object.entries(tag_types || {}).map(
					([tag, tag_type]) => {
						let title = idToTitle(tag);
						tag_type && (title += `[${tag_type}]`);
						return {
							"title": title,
							"tag": tag,
							"tag_type": tag_type
						}
					}
				)
			}
			onSelectedChange={
				(selected) => {
					let selected_tags_with_types = Object.fromEntries(selected.map((tag) => [tag.tag, tag.tag_type || ""]));
					onUpdated?.(selected_tags_with_types);
				}
			}
			suggestions={
				async (text) => {
					if(text.length < 3) return;
					let ret = {"selection": {"title": text, "tag": sanitizeToId(text)}};
					ret.view = (
						<div className='tw-flex tw-flex-align-center'>
							<div className='tw-grow'>{idToTitle(text)}</div>
							<select defaultValue={""}
								onClick={(evt) => evt.stopPropagation()}
								onChange={(evt) => {
									let title = text;
									let tag_type = evt.target.value
									tag_type && (title+= `[${tag_type}]`);
									ret.selection= {
										"title": title,
										"tag": sanitizeToId(text),
										"tag_type": tag_type
									}
								}}
							>
								<option value="">Generic</option>
								<option value="date">Date</option>
							</select>
						</div>
					);
					return [ret];
				}
			}
		/>
	);
}
export {TagsWithTypesEditor, AddTagsWithValues, SearchTagsWithEsValues};