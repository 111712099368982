import React, { useEffect, useState } from 'react'
import { getByIds } from 'base/get_by_ids';
import { DateView } from 'base/ui/date';

const FormErrorText = ({ msg }) => {
    return (<div className='tw-text-red-600 tw-text-xs tw-mt-1'>{msg}</div>)
}

const NumberBadge = ({val}) => (
    <div className='tw-text-white tw-text-xs flex-box'>
        <span className='tw-inline-block tw-bg-red-600' style={{lineHeight: 0, borderRadius: '50%'}}>
          <span className='tw-inline-block' style={{paddingTop: '50%', paddingBottom: '50%', marginLeft: 4, marginRight: 4}}>{val}</span>
        </span>
    </div>
)

export { FormErrorText, NumberBadge }