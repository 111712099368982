import React, { useEffect, useState } from 'react'
import { getByIds } from 'base/get_by_ids';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { DateView } from 'base/ui/date';


function LastUpdatedUser({ className, user_at }) {
    const [user, setUser] = useState(null);
    useEffect(
        () => {
            if (!user_at) return;
            getByIds({ "user_ids": [user_at.user_id] }).then((data) => {
                setUser(data.users[user_at.user_id]);
            });
        }, [user_at]
    );
    if (!user) return null;
    return <div className={`tw-flex tw-gap-1 tw-text-xxs tw-text-gray-400 ${className}`}>
        <p>Last Updated by:</p>
        <p className='tw-text-black'>{user.name}</p>
        <DateView millis={user_at.at} />
    </div>
}

function EditUserById({ className, user_id, onEdit }) {
    const [user, setUser] = useState(null);
    useEffect(
        () => {
            if (!user_id) return;
            getByIds({ "user_ids": [user_id] }).then((data) => {
                setUser(data.users[user_id]);
            });
        }, [user_id]
    );
    if (!user) return null;
    return <div className={`tw-flex tw-gap-1 tw-items-center ${className}`} onClick={(e) => {
        e.stopPropagation()
        onEdit()
    }}>
        <p className='tw-text-black'>{user.name}</p>
        <FontAwesomeIcon icon={faPencil} className='tw-mr-2' />
    </div>
}

const UserBadge = ({user}) => (
    <div key={user._id} className={'tw-flex tw-py-2 tw-px-4 tw-items-center'}>
        <div className='tw-w-10 tw-h-10 tw-rounded-full flex-box tw-text-secondary tw-bg-secondary-bg tw-overflow-hidden'>
            {!user.image 
                ?   <span>{user.name?.slice(0,1)}</span>
                :   <img alt={user.name} src={user.image} className='tw-object-fill' />
            }
        </div>
        <div className='tw-grow tw-px-2'>
            <div className="tw-text-gray-600 tw-text-sm tw-font-bold">{user.name}</div>
            <div className='tw-text-gray-500 tw-text-sm'>{user.phone_number || user.email_id}</div>
        </div>
    </div>
)

export { EditUserById, UserBadge, LastUpdatedUser }